<div class="module-whatsapp fixo" (click)="open()" *ngIf="template == 'fixo' || template == null">
    <div class="content">
        <span>Chama no <br><strong>WhatsApp!</strong></span>
        <div class="icon">
            <img src="/images/icons/whatsapp.svg" alt="WhatsApp" width="25px" height="25px" />
        </div>  
    </div> 
    <div class="content mobile">
        <div class="icon">
            <img src="/images/icons/whatsapp.svg" alt="WhatsApp" width="15px" height="15px" />
        </div>  
    </div>     
</div>  
<div class="module-whatsapp servico" *ngIf="template == 'servico'">
    <span class="title">Fale pelo WhatsApp:</span>
    <button tpe="button" class="btn-two btn-icon center" (click)="open()">
        <img src="/images/icons/whatsapp.svg" width="18px" height="18px" />
        <span>WhatsApp</span>
    </button>
</div>  
<div class="default-list" *ngIf="template == 'list'" (click)="open()">
    <div class="icon">
        <img src="/images/icons/whatsapp.svg" alt="WhatsApp" width="40px" height="40px" />
    </div>    
    <div class="content">
        <span class="title">Fale pelo WhatsApp:</span>
        <a href="#">{{data.params.telefone}}</a>  
    </div>
</div>  
