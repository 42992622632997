import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '../services/data.service';
import { TranslateService } from '../services/translate.service';

@Pipe({
  name: 'formatDias'
})
export class FormatDiasPipe implements PipeTransform {

  public linguagem = "pt-BR";

  constructor(
    private data:DataService,
    private translate: TranslateService
  ){

    this.linguagem = this.data.getLinguagem();

  }

  transform(dias:any,args:any):any {
    
    let result = "Todos os dias da semana.";
    let _e     = "e";

    if(this.linguagem != "pt-BR"){ 
      result = this.translate.getTraducao(result,this.linguagem);
      _e     = this.translate.getTraducao(_e,this.linguagem);
    }

    if(dias.length > 1){
      
      if(dias.length < 7){

        let d = "";

        for (let index = 0; index < dias.length;index++) {
          
          if(index > 0 && (index+1) < dias.length){
            d += ", ";
          }
          if(dias.length == (index+1)){
            d += ` ${_e} `;
          }

          if(this.linguagem == "pt-BR"){
            d += dias[index].descricao.substring(0,3);
          }else{
            d += this.translate.getTraducao(dias[index].descricao,this.linguagem);
          }
          
          
        }


        result = d+".";

      }

    }else{

      result = dias[0].descricao+".";

    }

    return result;

  }

}
